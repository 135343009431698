import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('hero-title',{attrs:{"title":"welcomeText","subtitle":"loginOrReg"}}),_c(VContainer,{staticClass:"mt-10"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VStepper,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperItems,[_c(VStepperContent,{staticClass:"pa-0",attrs:{"step":"1"}},[_c('login',{on:{"forgot-password-click":_vm.changeContent}})],1),_c(VStepperContent,{staticClass:"pa-0",attrs:{"step":"2"}},[_c('forgot-password',{on:{"login-click":_vm.changeContent}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }