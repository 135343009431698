<template>
  <div v-frag>
    <hero-title title="welcomeText" subtitle="loginOrReg"></hero-title>
    <v-container class="mt-10">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-stepper v-model="step">
            <v-stepper-items>
              <v-stepper-content step="1" class="pa-0">
                <login v-on:forgot-password-click="changeContent"></login>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <forgot-password v-on:login-click="changeContent"></forgot-password>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroTitle from '../components/titles/HeroTitle.vue';
import Login from '../components/auth/Login/Login.vue';
import ForgotPassword from '../components/auth/ForgotPassword/ForgotPassword.vue';

export default {
  name: 'LoginPage',
  components: {
    Login, ForgotPassword, HeroTitle,
  },
  props: {
    //
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    changeContent() {
      if (this.step === 1) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    }
  },
};
</script>

<style scoped>

</style>
