<template>
  <div v-frag>
    <v-card min-height="360">
      <v-card-title>
        <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
        <span>{{ $t('login') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-6 pt-6">
        <v-form v-model="loginFormIsValid">
          <v-text-field
            v-model="userData.email"
            :aria-label="$t('email')"
            :label="$t('email')"
            :rules="inputRules.required"
            filled
            required
            class="mb-3"
          ></v-text-field>
          <v-text-field
            v-model="userData.password"
            :aria-label="$t('password')"
            :label="$t('password')"
            :rules="inputRules.required"
            :type="showPass ? 'text' : 'password'"
            filled
            :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPass = !showPass"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <span class="caption caption-a" @click="forgotPasswordClick">{{ $t('forgotPassword') }}</span>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="Login">{{ $t('login') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="loginErrorDialog.visible" max-width="440px">
      <v-card>
        <v-card-title>
          <v-icon color="error" class="mr-4">mdi-alert-circle</v-icon>
          <span class="subtitle-1">{{ $t('downloadFileErrorDialog.title') }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <p class="body-1 text-center grey--text text--darken-4">
            {{ loginErrorDialog.message }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="loginErrorDialog.visible = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { saveToken } from 'src/utils/jwtHelper';

export default {
  name: 'Login',
  components: {
    //
  },
  beforeMount() {
    //
  },
  data() {
    return {
      showPass: false,
      loginFormIsValid: false,
      forgotPassFormIsValid: false,
      userData: {
        email: '',
        password: '',
      },
      inputRules: {
        required: [v => !!v || this.$t('inputMessages.requiredField')],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      loginErrorDialog: {
        visible: false,
        message: '',
      },
    };
  },
  methods: {
    forgotPasswordClick() {
      this.$emit('forgot-password-click');
    },
    async Login() {
      // console.log('route name: ', this.$route.name);
      const response = await this.$store.dispatch('Login', this.userData);
      // console.log('Login response: ', response);
      if (response.status === 200) {
        this.$router.push({name: 'iranyitopult'});
        /* if (this.$route.name === 'igenyles' || this.$route.name === 'home') {
          // window.location.reload();
        } else {
          await this.$router.push({name: 'home'});
          // window.location.reload();
        } */
      } else {
        this.loginErrorDialog.message = response.message;
        this.loginErrorDialog.visible = true;
      }
    }
  },
};
</script>

<style scoped>
</style>
