import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c(VCard,{attrs:{"min-height":"360"}},[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-4",attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")]),_c('span',[_vm._v(_vm._s(_vm.$t('forgotPassword')))])],1),_c(VDivider),_c(VCardText,{staticClass:"px-6 pt-6"},[_c(VForm,{model:{value:(_vm.forgotPassFormIsValid),callback:function ($$v) {_vm.forgotPassFormIsValid=$$v},expression:"forgotPassFormIsValid"}},[_c(VTextField,{staticClass:"mb-3",attrs:{"aria-label":_vm.$t('email'),"label":_vm.$t('email'),"rules":_vm.inputRules.emailRules,"filled":"","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticStyle:{"height":"86px"}},[_vm._v(" "+_vm._s(_vm.$t('forgotPasswordReminderText'))+" ")])],1),_c(VCardActions,{staticClass:"px-6 pb-6"},[_c('span',{staticClass:"caption caption-a",on:{"click":_vm.loginClick}},[_vm._v(_vm._s(_vm.$t('login')))]),_c(VSpacer),_c(VBtn,{staticClass:"primary",on:{"click":_vm.ResetPass}},[_vm._v(_vm._s(_vm.$t('button.send')))])],1)],1),_c(VDialog,{attrs:{"width":"90%","max-width":"340","persistent":true},model:{value:(_vm.loadingDialog.visible),callback:function ($$v) {_vm.$set(_vm.loadingDialog, "visible", $$v)},expression:"loadingDialog.visible"}},[_c('loading-dialog',{attrs:{"loading-message":_vm.loadingDialog.message}})],1),_c(VDialog,{attrs:{"width":"90%","max-width":"340","persistent":true},model:{value:(_vm.responseDialog.visible),callback:function ($$v) {_vm.$set(_vm.responseDialog, "visible", $$v)},expression:"responseDialog.visible"}},[_c('response-dialog',{attrs:{"response-object":_vm.responseDialog.response},on:{"close-dialog":_vm.closeResponseDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }