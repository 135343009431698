<template>
  <div v-frag>
    <v-card min-height="360">
      <v-card-title>
        <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
        <span>{{ $t('forgotPassword') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-6 pt-6">
        <v-form v-model="forgotPassFormIsValid">
          <v-text-field
            v-model="email"
            :aria-label="$t('email')"
            :label="$t('email')"
            :rules="inputRules.emailRules"
            filled
            required
            class="mb-3">
          </v-text-field>
        </v-form>
        <div style="height: 86px;">
          {{ $t('forgotPasswordReminderText') }}
        </div>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <span class="caption caption-a" @click="loginClick">{{ $t('login') }}</span>
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="ResetPass">{{ $t('button.send') }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
    <!-- RESPONSE DIALOGS END -->
  </div>
</template>
<script>
import LoadingDialog from 'src/components/dialogs/LoadingDialog.vue';
import ResponseDialog from 'src/components/dialogs/ResponseDialog.vue';

export default {
  name: 'ForgotPassword',
  components: {
    LoadingDialog, ResponseDialog,
  },
  beforeMount() {
    //
  },
  data() {
    return {
      showPass: false,
      loginFormIsValid: false,
      forgotPassFormIsValid: false,
      email: '',
      inputRules: {
        emailRules: [
          (v) => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          (v) => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          (v) => !!v || this.$t('inputMessages.requiredField'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_RESETPASSWORD',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
    };
  },
  methods: {
    loginClick() {
      this.$emit('login-click');
    },
    async ResetPass() {
      this.openLoadingDialog();
      const response = await this.$store.dispatch('ResetPassword', this.email);
      // console.log('ResetPass response: ', response);
      if (response === 200) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>Sikeres beküldés!</b></div>'
          + `<div class="headline mb-4">Kérése megérkezett hozzánk! A megadott e-mail címre (<b>${this.email}</b>) hamarosan visszaigazoló e-mailt küldünk!</div><div>Köszönjük megkeresését!</div>`;
      } else if (response === 204) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>204</b></div>'
          + `<div class="headline mb-4">Kérése megérkezett hozzánk! A megadott e-mail címre (<b>${this.email}</b>) hamarosan visszaigazoló e-mailt küldünk!</div><div>Köszönjük megkeresését!</div>`;
      } else if (response === 400) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>400 Váratlan hiba történt!</b></div>'
          + '<div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>';
      } else {
        this.responseDialog.response.message = this.ERROR_MESSAGES.FORM_FAIL_MESSAGE;
      }
      this.controlDialogs();
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      window.location.reload();
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
  },
};
</script>

<style scoped>
</style>
